import React from 'react'

export default () => (
    <style jsx="true">{`
.casevision{
padding:100px 0px;
 }
 .visionbg{
     background-color: #f4f4f4;
 }
 .visioncard{
     background-color: #fff;
    padding: 35px 45px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.14);
    border-radius: 4px;
 }

    `}</style>
)