import React from "react";
import Style from "./style"

function Applicationcards(props) {
    return(
        <div>
            <section className="application-border-cards-section">
                <div >
                    <div className={"shadow-card-flex " + props.mainColumn}>
                        <div className={" " + props.cardsSize}>
                            <div className="cards-with-shadow">
                                <h2 className="services-small-heading cards-stories-heading">New Horizons, Same Promise.</h2>
                                <div className="img-background"></div>
                                <span>OCTOBER 27, 2014 | NO COMMENTS |</span>
                                <p>New Horizons Same Promise: It’s been a
                                    while since I’ve blogged here. I’m happy to say that it’s finally
                                    time to unveil the real reason why.Redsqware has come a long way in five years.</p>
                                <button className="btn red-btn red-btn-big">Read More</button>
                            </div>
                        </div>
                        <div className={" " + props.cardsSize}>
                            <div className="cards-with-shadow">
                                <h2 className="services-small-heading cards-stories-heading">New Horizons, Same Promise.</h2>
                                <div className="img-background"></div>
                                <span>OCTOBER 27, 2014 | NO COMMENTS |</span>
                                <p>New Horizons Same Promise: It’s been a
                                    while since I’ve blogged here. I’m happy to say that it’s finally
                                    time to unveil the real reason why.Redsqware has come a long way in five years.</p>
                                <button className="btn red-btn red-btn-big">Read More</button>
                            </div>
                        </div>
                        <div className={" " + props.cardsSize}>
                            <div className="cards-with-shadow">
                                <h2 className="services-small-heading cards-stories-heading">New Horizons, Same Promise.</h2>
                                <div className="img-background"></div>
                                <span>OCTOBER 27, 2014 | NO COMMENTS |</span>
                                <p>New Horizons Same Promise: It’s been a
                                    while since I’ve blogged here. I’m happy to say that it’s finally
                                    time to unveil the real reason why.Redsqware has come a long way in five years.</p>
                                <button className="btn red-btn red-btn-big">Read More</button>
                            </div>
                        </div>
                        <div className={" " + props.cardsSize}>
                            <div className="cards-with-shadow">
                                <h2 className="services-small-heading cards-stories-heading">New Horizons, Same Promise.</h2>
                                <div className="img-background"></div>
                                <span>OCTOBER 27, 2014 | NO COMMENTS |</span>
                                <p>New Horizons Same Promise: It’s been a
                                    while since I’ve blogged here. I’m happy to say that it’s finally
                                    time to unveil the real reason why.Redsqware has come a long way in five years.</p>
                                <button className="btn red-btn red-btn-big">Read More</button>
                            </div>
                        </div>
                        <div className={" " + props.cardsSize}>
                            <div className="cards-with-shadow">
                                <h2 className="services-small-heading cards-stories-heading">New Horizons, Same Promise.</h2>
                                <div className="img-background"></div>
                                <span>OCTOBER 27, 2014 | NO COMMENTS |</span>
                                <p>New Horizons Same Promise: It’s been a
                                    while since I’ve blogged here. I’m happy to say that it’s finally
                                    time to unveil the real reason why.Redsqware has come a long way in five years.</p>
                                <button className="btn red-btn red-btn-big">Read More</button>
                            </div>
                        </div>
                        <div className={" " + props.cardsSize}>
                            <div className="cards-with-shadow">
                                <h2 className="services-small-heading cards-stories-heading">New Horizons, Same Promise.</h2>
                                <div className="img-background"></div>
                                <span>OCTOBER 27, 2014 | NO COMMENTS |</span>
                                <p>New Horizons Same Promise: It’s been a
                                    while since I’ve blogged here. I’m happy to say that it’s finally
                                    time to unveil the real reason why.Redsqware has come a long way in five years.</p>
                                <button className="btn red-btn red-btn-big">Read More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="application-mid-btn">
                <button className="btn red-btn">More Case Studies</button>
            </div>
            <Style/>
        </div>
    )
}
export default Applicationcards;