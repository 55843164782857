import React from "react";
import Style from "./style"
function GetInTouch() {
    return(
      <div>
          <section className="get-in-touch-section">
              <div className="container">
                  <div className="col-md-12 slected-padding-col">
                      <h2 className=".header-main-text selected-heading">
                          GET IN TOUCH </h2>
                      <hr className="user-slider-line" />
                      <p className="selected-p">orem ipsum dolor sit amet, consec
                          tetur adipiscing elit.
                          Vivamus mollis, elit et laoreet v
                          olutpat, purus massa placerat risus</p>
                  </div>
                  <div className="col-sm-12">
                      <div className="in-touch-columns">
                          <div className="col-sm-5">
                              <h3 className="services-small-heading">Write Us</h3>
                              <form className="write-form">
                                  <div className="form-group">
                                      <input type="text" placeholder="Name" className="form-control"/>
                                      <input type="email" placeholder="E-mail" className="form-control"/>
                                      <textarea placeholder="Message" className="form-control" rows="3"></textarea>
                                      <button className="btn red-btn">Send</button>
                                  </div>
                              </form>
                          </div>
                          <div className="col-sm-5">

                              <div className="write-us-details">
                                  <div className="black-area">
                                      <h3 className="services-small-heading">
                                          We are here
                                      </h3>
                                      <div className="write-us-detail-content">
                                          <p className="black-area-first-p">Adress</p>
                                          <p className="black-area-second-p">PO Box 16122 West Victoria 2010 USA</p>
                                      </div>
                                      <div className="write-us-detail-content">
                                          <p className="black-area-first-p">Phone</p>
                                          <p className="black-area-second-p">+1 564-334-52-22-34</p>
                                      </div>
                                      <div className="write-us-detail-content">
                                          <p className="black-area-first-p">E-mail</p>
                                          <p className="black-area-second-p">info@redsqware.com</p>
                                      </div>
                                  </div>
                                  {/*<iframe title="iframe" id="homepageIframe" src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13198.34207747834!2d73.2352409!3d34.2080651!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1547560078101"*/}
                                         {/*/>*/}
                                         <div className="first-small-map">

                                         </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>

          </section>
          <Style/>
      </div>
    )
}
export default GetInTouch;