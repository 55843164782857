import React from 'react'

export default () => (
    <style jsx="true">{`
.about-header{
    background-image: url(/images/about-head-bg.webp);
        min-height: 610px;
}
.about-main-container{
    background-color: #f4f4f4;
}
.about-header .about-head-text .header-main-text,.about-head-text p{
    color: white;
    margin: 0px!important;
}
.about-head-text{
padding: 144px 0px 0px 29px;
}

    `}</style>
)