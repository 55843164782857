import React from "react";
import Layout from "../components/layout";
import ApplicationMain from "../components/applicationpagecomponent"
function whatWeDo() {
    return(
        <Layout headimg ={"/images/top-logo.webp"} headclr={"white-list"} headbg={"transparent-bg"} callicon ={"/images/call-answer.webp"}>
            <ApplicationMain/>
        </Layout>
    )
}
export default whatWeDo;