import React from "react";
import Style from "./style"
function AboutHeader() {
    return(
        <div className="container-fluid p-0 about-main-container">
            <div className=" about-header">
                <div className="container">
                    <div className="about-head-text">
                        <h2 className="header-main-text">Human Development</h2>
                        <p className="services-detail-text">We take the uncertainty out of web and application development for
                            everyone.</p>
                    </div></div>
            </div>
        <Style/>
        </div>
    )
}
export default AboutHeader