import React from 'react'

export default () => (
    <style jsx="true">{`
.cards-with-shadow{
    background-color: #fff;
    padding: 15px 10px;
    box-shadow: 1px 1px 28px rgba(51, 51, 51, 0.17);
    margin-bottom: 30px;
}

.blog-small-cards-sections{
    background-color: #f4f4f4;
}
.blog-small-cards-sections .container{
    padding-top: 50px;
} .cards-stories-heading{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid #e6e6e6;
}
.cards-with-shadow .img-background{
    background-image: url(/images/default-placeholder-1024x1024.webp);
    background-size: cover;
    min-height: 250px;
    width: 100%;
    background-repeat: no-repeat;
}
.cards-with-shadow span{
    font-size: 12px;
    font-weight: 500;
}
.cards-with-shadow p{
    font-size: 14px;
    margin: 0 0 15px;
}
.cards-with-shadow .read-more-red{
    color: #d82b41;
    font-weight: 700;
}
.application-border-cards-section .col-sm-12 {
    display: flex;
    flex-wrap: wrap;
}
.application-mid-btn {
    text-align: center;
}
.shadow-card-flex, .blog-small-cards-sections .col-sm-12 {
    display: flex;
    flex-wrap: wrap;
}
    `}</style>
)