import React from "react";
import ApplicationMain from "../aboutmain";
import ApplicationDetail from "../applicationdetails";
import Vision from "../visionsection";
import Applicationcards from "../applicationcards";
import ContactUs from "../getintouch"
function applicationComponent() {
    return (
        <div>
            <ApplicationMain/>
            <Vision casevision={"casevision"}/>
            <ApplicationDetail/>
            <div className="container">
                <Applicationcards cardsSize={"col-sm-4"} mainColumn={"col-sm-12"}/></div>
            <ContactUs/>
        </div>
    )
}
export default applicationComponent;