import React from "react";
import Style from "./style";
function Vision(props) {

    return(
<div className= {" " + props.visionbg}>
    <div className="col-md-12 large-text-div p-0">
        <h1 className="large-text-title large-2">{props.heading}</h1>
    </div>

    <div className = {"container " + props.casevision}>
        <div className="services-data">
            <div className= {"col-sm-6 "  } >



                                   <div className={"servives-img "}  >
                                       <img src="/images/post-img-5.webp" className=" img-responsive center-block" alt=""/>
                                   </div>



            </div>
            <div className="col-sm-6">
                <div className={"Services-detail " + props.visioncard}  data-aos="fade-left">
                    <p className="services-detail-text">

                        We build tech teams. Agile web and applica
                        tion development represents both an opportun
                        ity and a potential risk. Redsqware answers th
                        e problem of balancing code, cost, and compliance.
                        We identify solutions, build teams, and manage delivery to fit the precise needs of each
                        customer. W
                        e accept 100% accountability for the end result o
                        f your project.


                    </p>

                </div>
            </div>
        </div>
        <div className="services-data">
            <div className="col-sm-6">
                <div  className={"Services-detail " + props.visioncard} data-aos="fade-right">

                    <p className="services-detail-text">


                        Regardless of your industry, there’s more competition th
                        at ever before. Whether you’re a large enterprise or a small
                        business, agile development capability is critical to keeping
                        pace. True agility requires that each team player is invested in o
                        verall success. We know that th
                        ere’s no substitute for caring about outcomes.

                    </p>
                    <button className="btn red-btn">Case Studies</button>
                    <button className="btn white-btn page-1-about">About Us</button>
                </div>
            </div>

            <div className="col-sm-6">
                <div className="servives-img" data-aos="fade-left">
                    <img src="/images/post-img-6.webp" className="img-responsive center-block" alt=""/>
                </div>
            </div>

        </div>
    </div>
    <Style/>
</div>
    )
}
export default Vision