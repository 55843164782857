import React from 'react'

export default () => (
    <style jsx="true">{`
.get-in-touch-section .selected-heading{
    color: black!important;
    font-weight: 300
}
.write-form .form-control{
    border: none;
    border-bottom: 1px solid #23252a;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 35px;
    padding: 0 0 15px 0;
    color: #73777e;
    resize: none
}
.write-form .form-control:focus{
    outline: none
}
.in-touch-columns{
    display: flex;
    justify-content: space-between
}
.black-area{
    background-color: #393a43;
    padding: 50px 30px;
}
.black-area .services-small-heading{
    color: white
}
.black-area-first-p{
    color: #b4b4b4;
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 3px;
}
.black-area-second-p{
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
}
.footer-subscribe{
    background-color: #1e1f22;
    padding: 25px 0;
    text-align: center;
    padding-top: 30px;
}
.subscribe-content{
    display: inline-flex;
    align-items: center
}
.subscribe-content .services-detail-text{
    margin: 0px;
    padding-right: 30px
}
#homepageIframe{
height:100%;
 width: 100%;
}
.slected-padding-col{
padding: 90px 0px;
}
.get-in-touch-section .selected-heading {
    color: black!important;
    font-weight: 300;
    text-align: center;
    font-size: 50px;
}
.user-slider-line {
    width: 70px;
    background-color: #d72c42;
    height: 3px;
}
.selected-p {
    color: #595d66;
    font-weight: 500;
    max-width: 600px;
    text-align: center;
    margin: 0 auto 0 auto;
}
.first-small-map{
height:150px;
width:100%;
 background-image: url(/images/Capture.webp);
    background-size: cover;
    background-repeat: no-repeat;
        margin-bottom: 60px
}
    `}</style>
)