import React from "react";

function applicationDetails() {
    return(
        <div>
            <section className="application-detail-section">
                <div className="container">
                    <p className="services-detail-text">Such as for the Health and Science industries. Quality progressive
                        web apps (PWAs) and purpose-built ADM solutions are now within the reach of any enterprise.
                        With a wealth of data warehousing options like AWS and Rackspace, and a broad spectrum of
                        compliant technologies, it’s no secret why more and more enterprises are deciding to build
                        their own solutions at scale.</p>
                    <p className="services-detail-text">Our application development and management teams represent today’s
                        most popular ADM platforms, frameworks, and skill sets. For half a decade, we’ve maintained a
                        winning track record for seamless, on-time delivery. We stand behind every single estimate
                        100%. Regardless of scope and scale. So you never have to worry about creeping costs or hidden
                        fees.</p>
                    <p className="services-detail-text">Whether you require inventory tracking, patient outreach, customer
                        relations management (CRM), a private social network, a calculation tool, systems integration,
                        business intelligence (BI) or something completely new – our architects, developers and
                        designers will help craft a powerful, scalable solution to precisely fit your needs – using
                        common standards. The right application can literally change the way in which you do business,
                        and can add substantial value for your entire organization.</p>
                </div>
            </section>
        </div>
    )
}
export default applicationDetails;